@include themify('table-content-container') using ($component) {
  section:has(> .table-content-container) {
    box-shadow: map-get($component, 'shadow');
  }
  
  .table-content-container {
    background-color: map-get($component, 'background');
  }
}

.table-content-container {
  scroll-behavior: smooth;
  position: relative;
  overflow: auto;

  min-height: 150px;
  max-height: 55vh;

  &.max-h-65vh {
    max-height: 65vh;
  }

  &.max-h-40vh {
    max-height: 40vh;
  }

  &.no-max-h {
    max-height: unset;
  }
  
  table.mat-table {
    .mat-no-data-row {
      fo-alert {
        display: block;
        margin: auto;
        width: fit-content;
      }
    }
  }
}

/**
 * New table styles
 */
/**
 * Theme
 */
 @include themify('table') using ($component) {
  table.mat-table {
    border: map-get($component, 'border');
    background-color: map-get($component, 'body', 'background');
  }

  table.mat-table,
  body:has(> tr) {
    /**
     * Cells
     */
    .mat-header-cell,
    .mat-row-custom-header .mat-cell {
      background-color: map-get($component, 'header', 'background');
      color: map-get($component, 'header', 'text');
      border-color: map-get($component, 'header', 'border');
      .mat-sort-header-arrow {
        color: map-get($component, 'header', 'text');
      }
    }
    

    .mat-cell,
    .mat-footer-cell {
      background-color: map-get($component, 'body', 'background');
      color: map-get($component, 'body', 'text');
      border-color: map-get($component, 'body', 'border-color');
    }

    /**
     * Events on hover
     */
    .mat-row:not(.mat-no-data-row):hover > .mat-cell,
    .mat-footer-row:not(.mat-no-data-row):hover > .mat-footer-cell {
      // Borders
      // &:has(+ .mat-row:hover),
      // &:hover:has(+ .mat-row) {
      //   .mat-cell {
      //     border-bottom: map-get($component, 'body', 'row', 'hover', 'border');
      //   }
      // }

      background-color: map-get($component, 'body', 'row', 'hover', 'background');
      color: map-get($component, 'body', 'row', 'hover', 'text');
    }

    /**
     * Events on Selected
     */
      .mat-row {
        &.selected {
          outline: map-get($component, 'body', 'row', 'selected', 'border');
          .mat-cell {
            background-color: map-get($component, 'body', 'row', 'selected', 'background');
          }
        }
      }      
  }
}

/**
 * General styles
 */
table.mat-table {
  table-layout: auto;
  width: 100%;
}

table.mat-table,
body:has(> tr) {
  /**
   * Row
   */
  .mat-header-row {
    &:first-child {
      .mat-header-cell {
        font-weight: bold;
      }
    }
  }

  .mat-header-row,
  .mat-row,
  .mat-header-cell,
  .mat-cell {
    min-height: 44px;
    height: fit-content;
  }

  .mat-row.mat-no-data-row {
    height: 100px;
    .mat-cell {
      border: none;
    }
  }

  /**
   * Cells
   */
  .mat-header-cell,
  .mat-cell,
  .mat-footer-cell {
    max-width: 250px;
    min-width: fit-content;
    padding: map-get($spacing, 200) map-get($spacing, 300);
    line-height: 1rem;
  }

  // Replace material values
  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding: map-get($spacing, 200) map-get($spacing, 300);
  }

  .mat-header-cell,
  .mat-row-custom-header .mat-cell {
    font-size: map-get($font-size, 150);
    font-family: 'Nunito Sans', sans-serif;
    text-align: left;
    .mat-sort-header-container {
      justify-content: flex-start;
      .mat-sort-header-content {
        text-align: left;
      }
    }

    &.cdk-column-select {
      .mat-sort-header-container {
        justify-content: start;
      }
    }
  }

  .mat-row-custom-header .mat-cell{
    font-weight: bold;
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: map-get($font-size, 100);
  }

  .mat-column-Index,
  .cdk-column-Index,
  .mat-column-select,
  .cdk-column-select {
    width: 56px;
  }

  .mat-column-actions,
  .cdk-column-actions {
    width: 88px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 !important;

    div {
      display: flex;
      justify-content: flex-end;
    }

    button.mat-icon-button {
      width: 24px;
      height: 24px;

      &:not(:last-child) {
        margin-right: map-get($spacing, 300);
      }
    }
  }

  /**
   * Cell variants
   */
  .cell {
    &--no-border-bottom {
      border-bottom: 0;
    }

    &--bold {
      font-weight: bold;
    }

    &--align-center,
    &--align-center .mat-sort-header-container {
      text-align: center;
      justify-content: center;
    }

    &--align-left {
      text-align: left;
    }

    &--align-right {
      text-align: right;
    }
  }
}

