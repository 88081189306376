/**
 ***************************************
 * Filters - mat-expansion-panel
 ***************************************
 */

.mat-expansion-panel {
  .table-filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    gap: 1rem;
    padding-bottom: 1rem;
  }
}
