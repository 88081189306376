/**
 * Theme
 */
@include themify('select') using ($component) {
  .mat-form-field {
    .mat-select-arrow {
      background-color:  map-get($component, 'text');
    }
  }
  .mat-select-panel {
    box-shadow: map-get($component, 'panel', 'shadow');
    .mat-option {
      background-color: map-get($component, 'option', 'background');
      color: map-get($component, 'option', 'text');
      &.mat-active {
        background-color: map-get($component, 'option', 'active', 'background');
        color: map-get($component, 'option', 'active', 'text');
      }

      &:hover,
      &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
      &.mat-selected:not(.mat-option-disabled) {
        background-color:  map-get($component, 'option', 'hover', 'background');
        color: map-get($component, 'option', 'hover', 'text');
        border-top: map-get($component, 'option', 'hover', 'border');
        border-bottom: map-get($component, 'option', 'hover', 'border');
      }

      fo-icon {
        width: map-get($font-size, 500);
        margin-right: map-get($spacing, 100);
        .mat-icon {
          height: map-get($font-size, 800);
        }
      }
    }
  }
}

/**
 ***************************************
 * General styles
 ***************************************
 */
.mat-form-field.mat-form-field-type-mat-select:not(.textarea-field) {
  .mat-select-arrow {
    // Remove previous icon
    border: 0;
    // Add new icon
    width: 24px;
    height: 24px;
    -webkit-mask-image: url('/assets/svg/chevron-down.svg');
    mask-image: url('/assets/svg/chevron-down.svg');
  }

  .mat-select-placeholder {
    height: 20px;
  }

  &.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0);
  }
}

/**
 * Option styles
 */

.mat-select {
  fo-icon {
    width: map-get($font-size, 500);
    margin-right: map-get($spacing, 100);
    .mat-icon {
      height: map-get($font-size, 800);
    }
  }
}

.mat-select-panel {
  .mat-option {
    height: 36px;
    font-size: map-get($font-size, 100);
  }
}

.mat-select-panel-wrap:has(.choose-account-select, .program-group-select) {
  width: fit-content;
}

.mat-select-panel-wrap:has(.program-group-select) {
  min-width: 400px;
}

.mat-select-panel-wrap .mat-select-panel {
  &.program-group-select,
  &.choose-account-select {
    max-width: unset !important;
  }
}

.mat-select-panel-wrap .mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option.choose-account-option,
.mat-select-panel .mat-option.program-group-option {
  height: fit-content;
  min-height: 2rem;
  padding: map-get($spacing, 100);
  width: 100%;

  .mat-option-text {
    width: unset;
    min-width: fit-content;
  }
}

// IDV page
.idv-container {
  .mat-select-trigger {
    padding-top: map-get($spacing, 300);
    div:first-child {
      display: flex;
      align-items: center;
    }
  }
}