// palette blue
$color-blue: #0e90e4; // blue-500

// palette gray
$color-white: #fff; // gray-0
$color-gray: #637280; // gray-1000
$color-gray2: #f7f7f7; // gray-50
$color-gray3: #32464c; // blue-1100
$color-gray4: #545454; // gray-1100

$color-gray-400: #ced4da; // gray-600
$color-gray-450: #b1b8c0; // gray-700
$color-gray-500: #adb5bd; // gray-800
$color-gray-600: #868e96; // gray-900

$gray-800: #1e293b; // gray-1200

$color-yellow-200: #fff9e5; // yellow-100

$color-red-400: #f44336; // red-500
$red-500: #f34056; // gray-600

$green-900: #026e00; // green-700

$blue-100: #f1f5f9; // blue-50
$blue-200: #edf8ff; // blue-100
$blue-500: #0d7bc5; // blue-600
$blue-800: #1e293b; // blue-1000

// Aliases
$site-color-primary: $color-blue;
$site-color-gray: $color-gray;
$site-color-white: $color-white;

$site-link-color: $site-color-gray;
$site-font-color: $site-color-gray;

$site-headers-color: $site-color-primary;

$site-footer-border-color: $color-gray2;

//home page
$site-title-h2-color: $color-gray3;

$color-alert-error: #721c24; // red-900
$color-alert-error-bg: #f8d7da; // red-100
$color-alert-success: #3a7f7c; // green-800
$color-alert-success-bg: #d1e7dd; // green-50
$color-alert-info: #004085; // blue-900
$color-alert-info-bg: #cce5ff; // blue-200
