@mixin set-button-details($component) {
  background-color: map-get($component, 'background');
  color: map-get($component, 'text');
  $box-shadow: map-get($component, 'shadow');
  @if $box-shadow {
    box-shadow: $box-shadow;
  }
  $border: map-get($component, 'border');
  @if $border {
    border: $border;
  }

  &.ladda-button[data-loading] {
    background-color: map-get($component, 'background');

    .ladda-spinner div[role='progressbar'] div div {
      background: map-get($component, 'text') !important;
    }
  }
}

@mixin set-button-styles($default, $hover, $focused: null) {
  @include set-button-details($default);

  // hover
  &:hover {
    @include set-button-details($hover);
  }

  // focused
  @if $focused {
    &.cdk-focused, &.cdk-mouse-focused, &:active {
      @include set-button-details($focused);
    }
  }

  .mat-button-focus-overlay,
  .mat-ripple.mat-button-ripple {
    opacity: 0;
    transition: none;
  }
}

/**
 * Theme
 */
@include themify('button') using ($component) {
  button {
    @each $type, $colors in $component {
      &.mat-#{$type}-button {
        &.mat-primary {
          $color: map-get($colors, 'primary');
          @include set-button-styles(
            map-get($color, 'default'),
            map-get($color, 'hover'),
            map-get($color, 'clicked')
          );
        }
        &.mat-button-disabled {
          &.mat-primary,
          &.mat-accent,
          &.mat-warn {
            cursor: not-allowed;
            $color: map-get($colors, 'disabled');
            @include set-button-styles(map-get($color, 'default'), map-get($color, 'hover'));
          }
        }
      }
    }
  }
}

/**
 * General styles
 */
button.mat-button,
button.mat-button-base {
  border-radius: 4px;
}

/**
 * Icon button
 */
button.mat-icon-button:not(.mat-calendar-next-button, .mat-calendar-previous-button) {
  width: 24px;
  height: 24px;
  line-height: 1;

  padding: 0;
  background-color: transparent;
  border: none;

  .mat-icon {
    line-height: 1;
  }

  fo-icon {
    display: block;
    color: inherit;
    width: inherit;
    height: inherit;
  }
}
