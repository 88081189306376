/**
 * Theme
 */
@include themify('expansion-panel') using ($component) {
  .filters .mat-expansion-panel:not([class*='mat-elevation-z']) {
    border: map-get($component, 'border');
    box-shadow: map-get($component, 'shadow');
  }
}

mat-expansion-panel.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

mat-expansion-panel {
  article {
    > header {
      font-weight: 600;
      margin-bottom: 1em;
    }
  }
}
