/**
 * Theme
 */
@include themify('tooltip') using ($component) {
  div.mat-tooltip {
    background-color: map-get($component, 'background');
    box-shadow: map-get($component, 'shadow');
    color: map-get($component, 'text');

    &:not(.mat-tooltip--no-tip) {
      &::after {
        border-color: map-get($component, 'background') transparent transparent transparent;
      }
    }
  }
}

/**
 * Tooltip
 */
div.mat-tooltip {
  font-size: map-get($font-size, 75);
  padding: map-get($spacing, 300);
  line-height: normal;
  text-align: left;
  overflow: visible;

  &:not(.mat-tooltip--no-tip) {
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      border-width: 5px;
      border-style: solid;
    }
  }
}