/**
 * Theme
 */
@include themify('dialog') using ($component) {
  .mat-dialog-container {
    .mat-dialog-title {
      border-bottom: map-get($component, 'border');
      
      button.mat-icon-button.button-close {
        color: map-get($component, 'button-close', 'color');
      }
    }

    .mat-dialog-actions {
      border-top: map-get($component, 'border');
    }

    h1 {
      color: map-get($component, 'title');
    }
  }

  .status-history {
    table.mat-table {
      .author--system {
        color: rgba(map-get($component, 'button-close', 'color'), 0.4);
      }
    }
  }
}

/**
  * Sections and container styles
  */
mat-dialog-container.mat-dialog-container {
  padding-bottom: 0;
  padding: 0;
  min-width: 350px;

  // Header
  .mat-dialog-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 22px 16px;
    margin-bottom: 0;

    .mat-icon-button.button-close {
      border: none;
      background: transparent;

      $button-size: 18px;
      $icon-margin: 2px;
      $icon-side: calc($button-size + $icon-margin * 2);

      max-height: $icon-side;
      max-width: $icon-side;
      margin-right: 12px;
      line-height: $button-size;

      .mat-icon {
        position: absolute;
        top: 0;
        left: 0;

        width: $icon-side;
        height: $icon-side;

        font-size: calc($button-size * 1.3);
        line-height: $icon-side;
      }
    }
  }

  .mat-dialog-content,
  .mat-dialog-actions {
    margin: 0;
    padding: 16px;
    min-height: unset;

    .mat-button-base {
      margin: 0 !important;
    }
  }

  // Content
  .mat-dialog-content {
    position: relative;

    .button-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;
    }

    &--no-actions {
      padding-bottom: 24px;
    }
  }

  .mat-dialog-actions {
    justify-content: flex-end;
    gap: 16px;
  }

  /**
    * Typography
    */
  h1 {
    font-size: 20px;
    line-height: 1rem;
    margin: 0;
  }

  h3 {
    font-size: 20px;
    line-height: 1rem;
    margin-bottom: 16px;
    font-weight: 400;
  }
}
