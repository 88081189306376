/**
 * Theme
 */
@include themify('chips') using ($component) {
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background-color: map-get($component, 'background');
    border: map-get($component, 'border');
    color: map-get($component, 'text');

    &.chip--selected {
      background-color: map-get($component, 'active', 'background');
      border: map-get($component, 'active', 'border');
      color: map-get($component, 'active', 'text');
    }

    /* Selected chip with error border color */
    &.chip--error {
      background-color: map-get($component, 'error', 'background');
      border: map-get($component, 'error', 'border');
      color: map-get($component, 'error', 'text');
    }
  }
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  border-radius: map-get($round-corners, 'large');
  font-size: map-get($font-size, 100);

  &.mat-chip {
    .mat-chip-remove {
      color: inherit;
      opacity: 1;
      margin-bottom: 2px;
    }
  }
}
