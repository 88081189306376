@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * Theme
 */
@include themify('tab') using ($component) {
  $header: map-get($component, 'header');
  .mat-tab-label-container {
    background-color: map-get($header, 'background');
    box-shadow: map-get($header, 'shadow');
  }

  $label: map-get($header, 'label');
  .mat-tab-labels {
    .mat-tab-label:first-child {
      border-left: 0;
    }

    .mat-tab-label:last-child {
      border-right: 0;
    }

    .mat-tab-label {
      background-color: map-get($label, 'background');
      border: map-get($label, 'border');
      border-top: none;
      color: map-get($label, 'text');

      &.mat-tab-label-active {
        background: map-get($label, 'active', 'background');
        color: map-get($label, 'active', 'text');
        box-shadow: map-get($label, 'active', 'shadow');
      }
    }
  }

  .mat-ink-bar {
    background-color: map-get($label, 'active', 'ink-bar');
  }
  
  $body: map-get($component, 'body');

  $variants: map-get($body, 'variants');
  @each $color, $variant in $variants {
    .tab--#{$color} {
      background-color: map-get($variant, 'background');
    }
  }
}

/**
 ***************************************
 * General styles
 ***************************************
 */

.mat-tab-group {
  .mat-tab-header {
    border: none;
    padding-bottom: map-get($spacing, 300);
    margin-bottom: -1*map-get($spacing, 300);
    z-index: map-get($z-layers, 'semi-small');
  }

  .mat-tab-body-content {
    overflow: hidden;
  }

  .mat-tab-body {
    margin-top: 0;
  }

  .mat-tab-labels {
    width: 100%;
    display: flex;

    .mat-tab-label {
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 700;
      font-size: $font-base;
      opacity: 1;

      flex-grow: 1;
      height: 60px;

      &.mat-tab-label-active {
        border-right: 0;
        z-index: map-get($z-layers, 'medium');
      }
    }

  }

  .mat-ink-bar {
    z-index: map-get($z-layers, 'up');
  }
}
