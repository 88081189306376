/**
 * Theme
 */
@include themify('checkbox') using ($component) {
  /**
   * Primary styles
   */
  mat-checkbox.mat-primary:not(.mat-checkbox-disabled), 
  mat-checkbox.mat-primary:not(.mat-checkbox-force-disabled) {
    .mat-checkbox-frame {
      border-color: map-get($component, 'primary');
    }
  }

  mat-pseudo-checkbox.mat-pseudo-checkbox {
    color: map-get($component, 'primary');
  }

  /**
   * Disabled states
   */  
  mat-checkbox.mat-checkbox-force-disabled:not(.mat-checkbox-disabled) {
    pointer-events: none;
  }

  mat-checkbox.mat-primary.mat-checkbox-disabled,
  mat-checkbox.mat-primary.mat-checkbox-force-disabled {
    cursor: not-allowed;
    .mat-checkbox-label {
      color: map-get($component, 'disabled', 'text');
    }
    .mat-checkbox-frame {
      border-color: map-get($component, 'disabled', 'frame');
    }
  }
}

/**
 ***************************************
 * General styles
 ***************************************
 */
mat-checkbox.mat-checkbox {
  .mat-checkbox-layout {
    margin-bottom: 0;
    .mat-checkbox-label {
      line-height: 1rem;
    }
  }
}

