$font-family-nunito: 'Nunito Sans', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;

@include themify('selection-list') using ($component) {
  .mat-selection-list {
    .mat-list-text {
      color: map-get($component, 'text');
      font-size: map-get($font-size, 200);
    }
  }

  .mat-list-single-selected-option {
    background-color: map-get($component, 'active');
  }
}

.mat-selection-list {
  font-family: $font-family-nunito;
  border-left: 1px solid map-get($gray-palette, 900);
}

mat-selection-list.mat-list-base .mat-list-item .mat-list-item-content-reverse, 
mat-selection-list.mat-list-base .mat-list-option .mat-list-item-content-reverse {
  padding: 0;
  padding-left: map-get($spacing, 400);
}