/**
 * Form field error
 */

mat-form-field.mat-form-field:has(mat-error) {
  margin-bottom: map-get($spacing, 300);

  fo-app-form-field-errors {
    position: absolute;
    bottom: calc(-1rem - map-get($spacing, 50));
    left: - map-get($spacing, 200);

    mat-error.mat-error {
      height: 1rem;
      margin: 0 !important;
    }
  }
}

/**
 * Theme
 */
@mixin form-field-theme-states($component) {
  @if $component {
    .mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: map-get($component, 'border-color');
      }
      input,
      .mat-form-field-suffix,
      .mat-form-field-label {
        color: map-get($component, 'label');
      }
      .mat-datepicker-toggle-active {
        color: map-get($component, 'suffix', 'active'); 
      }
  
      /**
       * State variants
       */
      $states: map-get($component, 'states');
      @each $state, $variant in $states {
        &.mat-#{$state},
        &.mat-form-field-#{$state} {
          .mat-form-field-outline {
            color: map-get($variant, 'border-color');
            $background-color: map-get($variant, 'background');
            @if $background-color {
              background-color: $background-color;
            }
          }
          // label
          input,
          .mat-form-field-suffix,
          .mat-mdc-icon-button:disabled,
          .mat-form-field-label {
            color: map-get($variant, 'label');
          }
        }
      }

      /**
       * SELECT
       */
      &.mat-form-field .mat-select-arrow {
        background-color: map-get($component, 'label');
      }

      &.mat-form-field .mat-select-value {
        color: map-get($component, 'label');
      }
    }
  }
}

@include themify('form-field') using ($component) {
  @include form-field-theme-states($component);
}

@include themify('form-field-hovered-row') using ($component) {
  .mat-row:not(.mat-no-data-row):hover > .mat-cell {
    @include form-field-theme-states($component);
  }
}

/**
 ***************************************
 * General styles
 ***************************************
 */
mat-form-field-label .mat-form-field-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field {
  width: 100%;
}

mat-form-field.mat-form-field.mat-form-field-appearance-standard {
  .mat-form-field-flex > .mat-form-field-infix {
    padding: 0.2em !important;
  }
  .mat-form-field-flex {
    padding-top: 0;
  }
  .mat-form-field-underline {
    bottom: 0;
  }
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    border-radius: 4px;
    // border: 1px solid var(--Line, rgba(0, 0, 0, 0.20));
  }

  &.mat-form-field-disabled,
  &.mat-form-field-disabled input,
  &.mat-form-field-disabled mat-select {
    cursor: not-allowed;
  }

  .mat-form-field-label mat-label,
  .mat-select-value-text {
    margin-bottom: 0;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1rem;
  }

  .mat-form-field-flex {
    padding: 0px 12px;
  }

  .mat-form-field-flex > .mat-form-field-infix {
    border: 0;
    padding: 16px 0px 12px 0px !important;
  }

  .mat-form-field-label-wrapper {
    top: 0;
    padding-top: 0;
  }

  .mat-form-field-label {
    top: 35%;
    margin-top: 0;
  }

  &.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
    width: 133.33333%;
  }
}

/**
 * Suffix
 */

.mat-form-field-suffix {
  .mat-datepicker-toggle,
  .mat-mdc-icon-button,
  .mat-mdc-icon-button:disabled {
    color: inherit;
  }
}

