/**
 * Nunito Sans & Roboto
 */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/**
 * Icons for angular material
 */
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

/**
 * Text styles
 */

.blue-text {
	color: #0e90e4;
	font-weight: 600;
}

.font-size-little {
	font-size: 16px;
}

.font-size-16 {
	font-size: 16px;
}

.line-height-mobile {
	line-height: 20px;
}

.little-font-size {
	font-size: 14px;
}

.average-font-size {
	font-size: 18px;
}

