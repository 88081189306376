/**
 ***************************************
 * My light theme
 ***************************************
 */

form {
  font-size: map-get($font-size, 100);

  textarea {
    height: 100px;
    resize: none;
  }

  .char-counter {
    font-size: map-get($font-size, 100);
    display: flex;
    justify-content: end;
  }
}

