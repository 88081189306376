/**
 * Theme
 */
@include themify('radio-button') using ($component) {
  .mat-radio-outer-circle {
    border-color: map-get($component, 'outer-circle');
  }
}

.mat-radio-label-content {
  letter-spacing: 0;
  font-size: $font-base;
  line-height: 1;
}
