/**
 * Theme
 */
@include themify('card') using ($component) {
  .mat-card {
    box-shadow: map-get($component, 'shadow');
  }
}

/**
 ***************************************
 * General styles
 ***************************************
 */
mat-card.mat-card {
  border-radius: map-get($round-corners, 'medium') !important;
  padding: map-get($spacing, 400) !important;
  font-size: map-get($font-size, 100);

  .card__title {
    font-size: $font-base;
    font-weight: 700;
    margin-bottom: map-get($spacing, 100);
  }

  * + .card__title {
    margin-top: map-get($spacing, 400);
  }

  .justify-self-end {
    justify-self: end;
  }

  .mat-card-actions {
    padding: 0;
    margin: 0 !important;

    &.floating-actions {
      position: absolute;
      right: map-get($spacing, 400);
      top: map-get($spacing, 400);
      z-index: 1;
    }
  }
}

/**
 * Card grid styles
 */
mat-card.mat-card.card-grid {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mat-card-content.main-layout,
  .mat-card-content .main-layout {
    font-size: map-get($font-size, 100);
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: map-get($spacing, 300);
    row-gap: map-get($spacing, 100);
    align-items: start;
  }

  .mat-card-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    
    padding: 0;
    margin-top: map-get($font-size, 400);
  }
}

// Client Info
.client-info.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  h2 {
    font-size: $font-base;
    margin-bottom: 16px;
    font-weight: bold;
  }

  p {
    margin-bottom: 8px;
  }

  .card-info {
    &--full {
      grid-column: 1/ -1;
    }
    .section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 1rem;
      &__title {
        grid-column: 1 / -1;
      }
    }
  }
}




// IDV verification
.idv-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 48px;
  row-gap: 24px;

  .attempt {
    margin-bottom: 24px;
  }

  .item-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  
    &--end {
      align-self: end;
    }
  }
  
  .sub-title {
    font-weight: 500;
    font-size: 15px;
  }
}