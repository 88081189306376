@use '@angular/material' as mat;

@import '@angular/material/theming';
@import 'core/settings/settings';
@import 'core/tools/tools';

$font-family-nunito: 'Nunito Sans', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
/* 
 * Define a typography
 */
$typography: mat.define-legacy-typography-config(
  $display-4:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: $font-base,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $display-3:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: map-get($font-size, 600),
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $display-2:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: map-get($font-size, 700),
      $line-height: 1,
    ),
  $display-1:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: map-get($font-size, 800),
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $headline:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: map-get($font-size, 500),
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $title:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: map-get($font-size, 500),
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $subheading-1:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: map-get($font-size, 300),
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $subheading-2:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: map-get($font-size, 300),
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $body-1:
    mat.define-typography-level(
      $font-family: $font-family-roboto,
      $font-weight: 400,
      $font-size: $font-base,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $body-2:
    mat.define-typography-level(
      $font-family: $font-family-roboto,
      $font-weight: 400,
      $font-size: $font-base,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $caption:
    mat.define-typography-level(
      $font-family: $font-family-roboto,
      $font-weight: 400,
      $font-size: $font-base,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $button:
    mat.define-typography-level(
      $font-family: $font-family-nunito,
      $font-weight: 700,
      $font-size: $font-base,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $input:
    mat.define-typography-level(
      $font-family: $font-family-roboto,
      $font-weight: 400,
      $font-size: map-get($font-size, 100),
      $line-height: 1,
      $letter-spacing: normal,
    ),
);

/********************************
 * Set material config
 ********************************/

 /**
 * Light by default
 */
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($typography);`
@include mat.all-legacy-component-typographies($typography);
@include mat.legacy-core();
@include mat.all-legacy-component-themes($material-light-theme);

.dark-theme {
  @include mat.all-legacy-component-colors($material-dark-theme);
}
