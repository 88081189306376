$font-family-nunito: 'Nunito Sans', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;

/**
 * Theme
 */
 @include themify('datepicker') using ($component) {
  .mat-calendar {
    .mat-calendar-body-in-range:before {
      background-color: map-get($component , 'background', 'hover');
    }

    .mat-calendar-body-selected {
      background-color: map-get($component , 'background', 'active');
    }
  }
}

// Calendar
.mat-calendar {
  &-header {
    font-family: $font-family-nunito;
    font-size: map-get($font-size, 500);
    font-weight: 800;
  }
  
  &-content {
    font-family: $font-family-roboto;
    font-size: map-get($font-size, 100);
    font-weight: 500;
  }

  &-body-cell-content,
  &-body-cell-content.mat-focus-indicator {
    border-radius: map-get($round-corners, 'small');
  }

  &-body-cell-container {
    height: 20px;
  }

  &-body-label {
    opacity: 0;
    
    &[colspan="7"] {
      display: none;
    }
  }
}

mat-form-field.mat-form-field .mat-form-field-suffix .mat-datepicker-toggle .mat-mdc-button-base {
  width: 24px;
}
