section.filters {
  width: 100%;
  padding: map-get($spacing, 400) map-get($spacing, 500);
  
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, 400);

  fo-button-spinner {
    align-self: flex-end;
  }

  form {
    gap: map-get($spacing, 300);
  }
}

.filters-accordion {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
