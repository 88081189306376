/* styling for bare HTML elements (like H1, A, etc.). These come with default styling from the browser so we can redefine them here. */

// General
@import 'card';
@import 'checkbox';
@import 'select';
@import 'radio-button';
@import 'dialog';
@import 'filters';
@import 'form';
@import 'form-field';
@import 'paginator';
@import 'chips';
@import 'input';
@import 'tabs';
@import 'button';
@import 'expansion-panel';
@import 'table';
@import 'tooltip';
@import 'datepicker';
@import 'selection-list';
@import 'autocomplete';

// Page
@import 'page-filters';

// Small elements
@include themify('layout') using ($component) {
  html,
  body,
  p,
  .mat-body {
    color: map-get($component, 'text');
  }

  .mat-display-4 {
    color: map-get($component, 'title');
  }

  .text-red,
  table.mat-table .mat-cell.text-red {
    color: map-get($component, 'error');
  }
}

@include themify('a') using ($component) {
  a, .link {
    color: map-get($component, 'text');
    &:hover {
      color: map-get($component, 'text-hover');
    }
  }
}

html,
body {
  font-family: 'Roboto';
  font-size: $font-base;
  height: 100%;
  margin: 0 !important;
}

.mat-display-4 {
  margin-bottom: map-get($spacing, 100);
}

a, .link {
  cursor: pointer;
  text-decoration: underline;
}

p {
  margin: 0;
}

.text-cursor {
  cursor: pointer;
}

/**
 * Grid elements
 */
.columns-full {
  grid-column: 1 / -1;
}

/**
 * OLD STYLES
 */
.item {
  &--report-generation {
    margin-bottom: 1rem;
  }
  &--report-expiration {
    margin-bottom: 2rem;
  }
}


// Snackbar
.snackbar {
  font-size: 1rem;
  &--error {
    color: $color-alert-error;
    background-color: $color-alert-error-bg;
    .mat-button-wrapper {
      color: $color-alert-error;
      font-weight: 600;
    }
  }
  &--success {
    color: $color-alert-success;
    background-color: $color-alert-success-bg;
    .mat-button-wrapper {
      color: $color-alert-success;
      font-weight: 600;
    }
  }
  &--info {
    color: $color-alert-info;
    background-color: $color-alert-info-bg;
    .mat-button-wrapper {
      color: $color-alert-info;
      font-weight: 600;
    }
  }
}

// Inputs
input.filter-autocomplete {
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: inherit;
  border-bottom: 1px solid $color-gray2;
}
