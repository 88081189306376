/**
 * Theme
 */
@include themify('paginator') using ($component) {
  .mat-paginator {
    .mat-paginator-container {
      background-color: map-get($component, 'background');
      color: map-get($component, 'text');

      .mat-icon-button {
        &.mat-button-disabled {
          color: map-get($component, 'text-disabled');
        }

        &.mat-focus-indicator.mat-button-base:not([color], .mat-button-disabled) {
          color: currentColor;
          &:hover {
            color: map-get($component, 'text-hover');
          }
        }
      }

      .mat-paginator-page-size {
        .mat-select-trigger,
        .mat-select-value,
        .mat-select-arrow {
          color: currentColor;
        }

        .mat-form-field-underline {
          display: none;
        }
      }
    }
  }
}

/**
 * Paginator
 */
.mat-paginator {
  $paginator-size: 24px;
  font-size: map-get($font-size, 100);

  .mat-paginator-container {
    min-height: $paginator-size;
    padding: 0 map-get($spacing, 300);

    .mat-paginator-page-size {
      margin-right: 0;
      gap: map-get($spacing, 100);
      .mat-paginator-page-size-select.mat-form-field-appearance-standard {
        margin: 0;
        height: $paginator-size;
        width: fit-content;

        line-height: $paginator-size;
        letter-spacing: 0;

        .mat-form-field-flex {
          padding-top: 0;
          & > .mat-form-field-infix {
            padding: 0 !important;
          }
        }

        .mat-form-field-infix {
          border-top: none;
          width: inherit;
          .mat-select-value-text {
            font-size: map-get($font-size, 100);
          }
        }
      }
    }

    .mat-paginator-range-actions {
      gap: map-get($spacing, 100);
      .mat-paginator-range-label {
        margin: 0 map-get($spacing, 300) 0 map-get($spacing, 400);
      }
    }

    .mat-icon-button {
      width: $paginator-size;
      height: $paginator-size;
      line-height: $paginator-size;

      .mat-paginator-icon {
        width: inherit;
      }
    }
  }
}
