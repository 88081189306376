@use '@angular/cdk' as cdk;
@import "theme";
@import "components";


// input {
//   @include cdk.text-field-autofill-color(transparent);
// }

// input:-webkit-autofill {
//   -webkit-box-shadow: 0 0 0px 1000px white inset;
// }

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  color: rgba(99, 114, 128, 0.87);
}

/**
 * Datepicker
 */
.mat-datepicker-toggle,
.mat-datepicker-toggle-default-icon {
  color: rgba(99, 114, 128, 0.54);
}
